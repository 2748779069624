import {
  Autocomplete,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import NextImage from 'next/image';
import { FlexBox } from '@web/components/flex-box';
import { H6 } from '@web/components/Typography';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { currencyFormatter } from '@web/utils/currency';
import { useAnalytics } from '@web/context/analytics';
import { useRouter, useSearchParams } from 'next/navigation';
import { useDropdownContext } from '@web/context/dropdown';
import HistoryIcon from '@mui/icons-material/History';
import CategoryIcon from '@mui/icons-material/Category';
import { useSearch } from '@web/context/search';

const SearchInput = ({ isMobile, inputField }: any) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { dropdownOpen, setDropdownOpen } = useDropdownContext();
  const [searchInput, setSearchInput] = useState<any>('');
  const { frequentSearch, searchHistory } = useSearch();
  const { trackSearch } = useAnalytics();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    if (searchParams?.get('q')) {
      setSearchInput(searchParams?.get('q'));
    }
  }, [searchParams]);

  const inputRef = useRef<any>(null);
  const [customPopperEl, setCustomPopperEl] = useState(null);

  useEffect(() => {
    if (inputRef.current) {
      setCustomPopperEl(inputRef.current);
    }
  }, [inputRef]);

  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    if (dropdownOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.click();
        inputRef.current?.focus();
      }, 100);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [dropdownOpen]);

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      const search = event.target?.value;
      setTimeout(() => {
        const term = event.target?.value || search;
        if (!term) return;
        setDropdownOpen(false);
        trackSearch(term);
        window.location.href = `/product/search?q=${encodeURIComponent(term)}`;
      });
    }
  };

  const handleDefaultSearch = useCallback(
    (option) => {
      setDropdownOpen(false);
      trackSearch(option.name);
      window.location.href = `/product/search?q=${encodeURIComponent(
        option.name
      )}`;
    },
    [router]
  );
  const CustomPopper = (props) => {
    const { children } = props;
    if (!customPopperEl) return;
    return (
      <Popper
        open={dropdownOpen}
        anchorEl={customPopperEl || ''}
        placement="bottom-start"
        style={
          isMobile
            ? { width: '100%', height: '100vh', zIndex: 10 }
            : { zIndex: 20 }
        }
      >
        <Box mt={1}>{children}</Box>
      </Popper>
    );
  };

  const displayPriceRange = (product) => {
    const { lowest_price, highest_price } = product;

    return lowest_price === highest_price
      ? currencyFormatter.format(lowest_price)
      : `${currencyFormatter.format(lowest_price)} - ${currencyFormatter.format(
          highest_price
        )}`;
  };
  const handleSearchChange = (event, value) => {
    setSearchInput(value);
  };

  const handleItemClick = useCallback(
    (option) => {
      const path =
        option.history || option.frequent
          ? `/product/search?q=${encodeURIComponent(option.name)}`
          : option.id
          ? `/product/${option.slug}`
          : `/category/${option.name}`;
      trackSearch(option.slug || option.name);
      window.location.href = path;
    },
    [router]
  );

  const results = useMemo(() => {
    return searchInput
      ? searchHistory
          .filter((s) => s.name?.includes(searchInput.toLowerCase()))
          .concat(
            frequentSearch
              .filter((s) => s.name?.includes(searchInput.toLowerCase()))
              .slice(0, 5)
          )
      : searchHistory.length < 10
      ? searchHistory.concat(frequentSearch.slice(0, 10 - searchHistory.length))
      : searchHistory;
  }, [searchInput, searchHistory, frequentSearch]);

  return (
    <Autocomplete
      freeSolo
      inputValue={searchInput}
      onInputChange={handleSearchChange}
      options={
        results.length ? results : [{ name: searchInput, default: true }]
      }
      open={dropdownOpen}
      onOpen={() => {
        setDropdownOpen(true);
      }}
      onClose={() => (!isMobile || searchInput) && setDropdownOpen(false)}
      getOptionLabel={(option: any) => option.title || option.name || ''}
      filterOptions={(options) => options}
      renderOption={(props, option) => {
        props = {
          ...props,
          key: option.id || props.id,
        };
        return option.id ? (
          <MenuItem {...props} onClick={() => handleItemClick(option)}>
            <ListItemIcon>
              <NextImage
                src={option.image}
                alt={option.title}
                width={40}
                height={40}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography ml={1} variant="inherit" noWrap>
                {option.title}
              </Typography>
            </ListItemText>
            <FlexBox alignItems="center" gap={1}>
              <Box fontWeight="600" color="primary.main">
                {displayPriceRange(option)}
              </Box>
              <Box>
                {option.offer_amount > 1 && `(${option.offer_amount} offers)`}
              </Box>
            </FlexBox>
          </MenuItem>
        ) : !option.default ? (
          <MenuItem {...props} onClick={() => handleItemClick(option)}>
            <ListItemIcon>
              {option.history ? (
                <HistoryIcon />
              ) : option.frequent ? (
                <SearchOutlinedIcon />
              ) : (
                <CategoryIcon />
              )}
            </ListItemIcon>
            <ListItemText>
              <FlexBox alignItems="center" gap={1}>
                <Box>
                  <>
                    <>
                      {option.name?.substring(
                        0,
                        option.name?.indexOf(searchInput.toLowerCase())
                      )}
                    </>
                    <strong>
                      {option.name?.substring(
                        option.name?.indexOf(searchInput.toLowerCase()),
                        option.name?.indexOf(searchInput.toLowerCase()) +
                          searchInput.length
                      )}
                    </strong>
                    <>
                      {option.name?.substring(
                        option.name?.indexOf(searchInput.toLowerCase()) +
                          searchInput.length
                      )}
                    </>
                  </>
                </Box>
                {option.amount && <H6>({option.amount})</H6>}
              </FlexBox>
            </ListItemText>
          </MenuItem>
        ) : (
          <MenuItem {...props} onClick={() => handleDefaultSearch(option)}>
            <ListItemText>
              <FlexBox alignItems="center" gap={1}>
                <Box>
                  <strong>{option.name}</strong>
                </Box>
              </FlexBox>
            </ListItemText>
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <FlexBox alignItems="center">
          {inputField ? (
            inputField({
              ...params,
              onKeyDown: handleEnterKey,
            })
          ) : (
            <TextField
              {...params}
              inputRef={inputRef}
              fullWidth
              variant="outlined"
              placeholder="Let's go shopping"
              onKeyDown={handleEnterKey}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <FlexBox ml={1} alignItems="center">
                    <SearchOutlinedIcon fontSize="small" />
                  </FlexBox>
                ),
                sx: {
                  borderRadius: 300,
                  color: 'grey.700',
                  overflow: 'hidden',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />
          )}
        </FlexBox>
      )}
      PopperComponent={isMobile && isSmallScreen ? CustomPopper : undefined}
      ListboxProps={{
        style: {
          ...(isMobile && isSmallScreen
            ? { minHeight: 'calc(100vh - 40px)', paddingBottom: 100 }
            : { maxHeight: '800px' }),
        },
      }}
    />
  );
};
export default SearchInput;
