import { Box, TextField, useMediaQuery } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { H1, H6 } from '@web/components/Typography';
import CountUp from 'react-countup';
import SearchInput from '@web/components/layouts/SearchInput';
import { useDropdownContext } from '@web/context/dropdown';
import { useTheme } from '@mui/material/styles';

const leftImg = '/assets/images/headers/shop-4.png';
const rightImg = '/assets/images/headers/shop-5.png';

const Container = ({ ...props }) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={{
        width: '100%',
        px: 1,
        mt: 8,
        backgroundColor: theme.palette.grey[100],
        backgroundSize: '40%, 40%',
        backgroundPosition: 'left top, right top',
        backgroundRepeat: 'no-repeat, no-repeat',
        transition: 'all .3s',
        backgroundImage: `url('${leftImg}'), url('${rightImg}')`,
        '& .title': {
          display: 'flex',
        },
        '& h1': {
          fontSize: 38,
          mb: 2,
          lineHeight: 1.3,
        },
        '& h6': {
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: '0.025em',
          mt: 2,
        },
        '& .searchBox': {
          m: 'auto',
          maxWidth: '600px',
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: theme.shadows[2],
        },
        [theme.breakpoints.up('md')]: {
          height: 380,
          backgroundSize: '450px, 450px',
        },
        [theme.breakpoints.down('md')]: {
          '& .title': {
            display: 'block',
          },
          '& h1': {
            fontSize: 30,
            display: 'block',
            textAlign: 'center',
          },
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: 2,
          '& h1': {
            fontSize: 20,
          },
          '& h6': {
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            m: '10px 12px',
            fontWeight: 400,
          },
          '& .searchBox': {
            margin: 0,
          },
          '& .description': {
            display: 'none',
          },
        },
        ...props.sx,
      }}
    />
  );
};

const HeroSection = ({ isFixed }) => {
  const { dropdownOpen } = useDropdownContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Container>
      <H1 maxWidth={600} mx="auto" pt={{ xs: 0, md: 6 }}>
        <Box className="title">
          Compare
          <Box ml={1} color="primary.main">
            More.
          </Box>
        </Box>
        <Box className="title">
          Pay
          <Box ml={1} color="success.main">
            Less.
          </Box>
        </Box>
      </H1>
      <Box className="searchBox">
        {(!dropdownOpen || !isMobile) && !isFixed && (
          <SearchInput
            isMobile={isMobile}
            inputField={(params) => (
              <TextField
                {...params}
                placeholder="Search for products, brands and more"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    height: 50,
                    paddingRight: 0,
                    color: 'grey.700',
                    background: '#fff',
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                  startAdornment: <SearchOutlinedIcon fontSize="small" />,
                }}
              />
            )}
          />
        )}
      </Box>
      <H6 className="description" maxWidth={600} mx="auto">
        <Box>
          BuyWisely compares prices from over{' '}
          <Box color="primary.main" width={85} display="inline-block">
            <CountUp
              end={2901801}
              duration={10}
              useEasing={true}
              start={2900000}
            />
          </Box>
          products across{' '}
          <Box color="primary.main" width={50} display="inline-block">
            <CountUp end={22990} duration={10} useEasing={true} start={21000} />
          </Box>
          online stores, ensuring you always land the best deal!
        </Box>
      </H6>
    </Container>
  );
};
export default HeroSection;
